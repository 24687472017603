import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import ApiService from "./core/services/api.service";
import helpers from "./core/services/helpers.service";
// import MockService from "./core/mock/mock.service";
import { VERIFY_AUTH } from "./core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VImageInput from 'vuetify-image-input/a-la-carte';
import VueSweetalert2 from 'vue-sweetalert2';
import jwtService from "@/core/services/jwt.service";
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

// Others
import MixCommon from "./core/mixins/common";
Vue.mixin(MixCommon);

import MasterAsyncCommon from "./core/mixins/master-async";
Vue.mixin(MasterAsyncCommon);

import Master from "./core/mixins/master";
Vue.mixin(Master);

// :momentjs
const moment = require('moment')
require('moment/locale/id')
Vue.use(require('vue-moment'), { moment })
// :end momentjs

// :sweetalert
Vue.component('VImageInput', VImageInput);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
// :end sweetalert

// :mask
import VuetifyMask from "vuetify-mask";
Vue.use(VuetifyMask);
// :end mask

// :export csv json
import JsonCSV from 'vue-json-csv';
Vue.component('downloadCsv', JsonCSV);
// :end export csv json

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// :helpers
const global = {
  install () {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  }
}
Vue.use(global)
// :end helpers

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Ensure we checked auth before each page load.
  // let user = JSON.parse(localStorage.getItem('data_user'))
  let mdl = jwtService.getModule() ? jwtService.getModule() : "[]";
  let first = mdl.charAt(0);
  let last = mdl.slice(-1);

  if(first == '[' && last == ']'){
    mdl = JSON.parse(mdl);
  }else{
    mdl = [];
  }

  let menu = mdl;
  console.debug("main", menu);

  Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
    if(to.matched.some(record => record.meta.isUser)){
      let index = menu.findIndex(res => res.module_kode === "M01");
      if(index >= 0) next();
      else next({name: 'builder'});
    }else if(to.matched.some(record => record.meta.isMaster)){
      let index = menu.findIndex(res => res.module_kode === "M02");
      if(index >= 0) next();
      else next({name: 'builder'});
    }else if(to.matched.some(record => record.meta.isDashboard)){
      let index = menu.findIndex(res => res.module_kode === "M03");
      if(index >= 0) next();
      else next({name: 'builder'});
    }else if(to.matched.some(record => record.meta.isTransaction)){
      let index = menu.findIndex(res => res.module_kode === "M04");
      if(index >= 0) next();
      else next({name: 'builder'});
    }else{      
      next()
    }
  });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
