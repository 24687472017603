const ID_TOKEN_KEY = "id_token";
const USER_DATA = "data_user";
const MODULE_DATA = "data_module";
const YEAR = new Date().getFullYear();

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getUser = () => {
  return window.localStorage.getItem(USER_DATA);
};

export const saveUser = user => {
  window.localStorage.setItem(USER_DATA, user);
};

export const destroyUser = () => {
  window.localStorage.removeItem(USER_DATA);
};

export const getModule = () => {
  return window.localStorage.getItem(MODULE_DATA);
};

export const saveModule = module => {
  window.localStorage.setItem(MODULE_DATA, module);
};

export const destroyModule = () => {
  window.localStorage.removeItem(MODULE_DATA);
};

export const getYear = () => {
  return YEAR;
};

export default { 
  getToken, 
  saveToken, 
  destroyToken, 
  getUser, 
  saveUser, 
  destroyUser, 
  getModule, 
  saveModule, 
  destroyModule, 
  getYear 
};
