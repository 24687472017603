import ApiService from "@/core/services/api.service";
import moment from 'moment';
import XLSX from 'xlsx';

export default {
    name: "MixCommon",
    component:[
    ],
    async mounted() {
        // ...
    },
    data: function () {
        return {
            // Main

            // ::Variable
            dataPagination: [5,10,15,25,50,100],
            
            messageTitleWarning: "Warning",
            
            messageDelete: "Are you sure you want to delete this data ?",
            messageDeleteSuccess: "data was successfully deleted",
            
            messageEdit: "Are you sure to update this data ?",
            messageEditSuccess: "Data was successfully changed",
            
            messageAdd: "Are you sure to create this data ?",
            messageAddSuccess: "Data was successfully added",
            
            messageUploadFailed: "failed to upload",
            
            images: {},

            // ::Plugin
            moment: moment,

            // ::Validation
            vRequired(v){ 
              return !!v || 'Field is required.';
            },
            vNumber(v){ 
              return Number.isInteger(v) || "The value must be an integer number";
            },
            vMinCur(v, min){
                let val = (min/1).toFixed(0).replace('.', ',')
                let res = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                return Number(v) >= Number(min) || `Min Rp ${res}`;
            },
            vMaxCur(v, max) { 
              let val = (max/1).toFixed(0).replace('.', ',')
              let res = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              return Number(v) <= Number(max) || `Max Rp ${res}.`;
            },
            vMinLength(min) { 
                v => (v || '').length >= min || `Minimal ${min} karakter`;
            },
            vMaxLength(max) { 
                v => (v || '').length <= max || `Maximal ${max} karakter`;
            },
            vEmail(v){
                return !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            },
            vKTP(v){
                return ( v && v.length == 16 ) || "KTP must have 16 characters"
            },
            vKK(v){
                return ( v && v.length == 16 ) || "KK must have 16 characters"
            },
            vCheckbox(v){
              return v.length > 0 || 'One of them must be chosen'
            },
            vInputMin(min, v){
              return (v || []).length >= min || 'Minimum '+min+' File'
            },
            vInputMax(max, v){
              return (v || []).length <= max || 'Maximum '+max+' File'
            },
            
        }
    },
    methods: {
      // ::Untuk pembantu dalam pembuatan index dalam list konten
      buildContentsIndex(data, options = null) {
        let start = 0;
        if(options){
          const { page, itemsPerPage } = options;
          start = (page - 1) * itemsPerPage;
        }
        return data.map((item, index) => ({...item,index: start + index + 1}))
      },

      // buildContentsAge(data) {
      //   return data.map((item, index) => ({...item, index: index + 1}))
      // },

      // ::Pengencekan Null 
      isNull(val){ return val ? val : "-"},

      // ::Pengecekan Array Null
      isArr(val){ return val ? val : []},
      
      // ::Pengecekan Object Null
      isObj(val){ return val ? val : {}},

      // ::Pengencekan String Null
      isStr(val){ return val ? String(val) : null},

      // ::Pengecekan Number null
      isNum(val){ return val ? Number(val) : null},

      // ::Mengubah String jadi huruf kecil
      toLowerCase(val){ return val.toLowerCase();},
      
      // ::Membuat Format Number menjadi Format Mata Uang
      formatPrice(number){
        let val = (number/1).toFixed(2).replace('.', ',')
        return val != "NaN" ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0;
      },

      // ::Untuk mengubah file menjadi base64 (Menampilkan image sementara) 
      generateImage(e, index) {
        var url       = null;
        if(e){ url    = URL.createObjectURL(e); };
        this.images[index] = url;
      },
      dateTimeNow(){
        return moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      },
      dateNow(){
        return moment(new Date()).format("YYYY-MM-DD");
      },
      timeNow(){
        return moment(new Date()).format("HH:mm:ss");
      },
      timeNowHHMM(){
        return moment(new Date()).format("HH:mm");
      },
      dateMonthYear(){
        return moment(new Date()).format("Do MMMM YYYY");
      },
      addDays(day){
        return moment(new Date()).add(day,'d').format("YYYY-MM-DD");
      },

      // ::Untuk mengambil path dalam object || **ada di API terbaru golang (Script bang udin)
      getPath(e, broken = null){
        if(typeof(e) === "object" && e !== null){
          if(!e.path || e.path == ""){
            return broken;
          }
          return e.path
        }else{
          return broken
        }
      },
      
      // ::Untuk mengambil single object dalam array berdasarkan value
      findNameArray(id, array){
        let name = undefined;
        if(id){
          let index = array.findIndex(item => item.value == id);
          if(index > -1){
            name = array[index].text;
          } 
        }
        return name;
      },
      
      // ::Untuk mengambil multi object dalam array berdasarkan value (arr)
      findNameArrayList(arrID, array){
        let arrName = [];
        if(arrID.length >= 0){
          for (let i = 0; i < arrID.length; i++) {
            const id = arrID[i];
            
            let index = array.findIndex(item => item.value == id);
            if(index > -1){
              arrName.push(array[index].text);
            } 
          }
        }
        return arrName;
      },

      // ::Untuk mengambil dropdown yang ada field free text 
      findFreeText(arr, data){
        let index = data.findIndex(res => res.freetext === true);
        if (index >= 0) {
            var freetext = data[index];
            var isFree = arr.findIndex(res => res === freetext.value);
            if (isFree >= 0) return true; 
        }
        return false;
      },

      // ::Untuk membuat format dropdown
      buildDD(arr, text, value){
        const respond = arr.map(item => {
          const row = {};

          row[item[text]]  = item[text];
          row[item[value]] = item[value];

          return row;
        })

        return respond;
      },

      // ::Untuk membentuk stuktur datatable
      dtBuild(options, main, search, params) {
        return new Promise((resolve, reject) => {
          const { sortBy, sortDesc, page, itemsPerPage } = options;
          
          let dataSort = {};
          let isSort = sortDesc.length;
          if(isSort > 0){
            for(let i = 0; i < isSort; i++){
              let item1 = sortBy[i];
              let item2 = sortDesc[i];
              
              if(item2 == true){
                dataSort[item1] = "DESC";
              }else{
                dataSort[item1] = "ASC";
              } 
            }
          }
  
          dataSort["id"] = "DESC";
  
          var query = { 
            start: (page - 1) * itemsPerPage,
            length: itemsPerPage,
            main: main,
            order: dataSort,
            search: search
          }
  
          ApiService.setHeader();
          ApiService.post(params, query).then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err.response);
          })
  
        })
      },

      // ::Untuk membentuk stuktur datatable
      dtBuildGet(options, search, source) {
        return new Promise((resolve, reject) => {
          const { page, itemsPerPage } = options;
  
          let params = {
            page_offset : (page - 1) * itemsPerPage,
            page_size : itemsPerPage,
            ...search
          };

          let url = source + this.buildParams(params);
  
          ApiService.setHeader();
          ApiService.get(url).then(res => {
            resolve(res.data)
          }).catch(err => {
            reject(err.response);
          })
  
        })
      },

      // ::Membentuk Object menjadi params
      buildParams(obj){
        console.log(obj);
        let params = "";
        Object.entries(obj).forEach(([key, value], index) => {
          console.log(value);
          console.log(key);
          if(index == 0){
            params += "?" + key + "=" + value;
          } else {
            if(value){
              params += "&" + key + "=" + value;
            }
          }
        });

        return params;
      },

      // ::Untuk pembantu export excel
      exportExcel(columns, data, filename, sheetname) {
        let createXLSLFormatObj = [];
        let newXlsHeader = [];
        if (columns.length === 0){
            console.log("Add columns!");
            return;
        }
        if (data.length === 0){
            console.log("Add data!");
            return;
        }
        columns.forEach(value => {
          newXlsHeader.push(value.label);
        });
        createXLSLFormatObj.push(newXlsHeader);
        
        data.forEach(value => {
          let innerRowData = [];
          
          columns.forEach(val => {
            if (val.dataFormat && typeof val.dataFormat === 'function') {
                innerRowData.push(val.dataFormat(value[val.field]));
            }else {
                innerRowData.push(value[val.field]);
            }
          });
          createXLSLFormatObj.push(innerRowData);
        });
        filename = filename + ".xlsx";
        let ws_name = sheetname;
        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        XLSX.writeFile(wb, filename);
      }
    }
};
