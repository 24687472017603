import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
    name: "MixGeneralPromise",
    computed: {
      ...mapGetters([
        "currentUser",
        "currentModule"
      ])
    },
    async mounted() {
        // ...
    },
    data: function () {
        return {
            typeDropdown: "dropdown",

            ddCabang: [],
            ddCabangLoading: false,

            ddRelation: [],
            ddRelationLoading: false,

            ddItem: [],
            ddItemLoading: false,

            ddGender: [],
            ddGenderLoading: false,

            ddOwnership: [],
            ddOwnershipLoading: false,

            ddJenisRumah: [],
            ddJenisRumahLoading: false,

            ddDesignRumah: [],
            ddDesignRumahLoading: false,

            ddSumberAir: [],
            ddSumberAirLoading: false,

            ddLantaiRumah: [],
            ddLantaiRumahLoading: false,

            ddAsset: [],
            ddAssetLoading: false,

            ddJenisHutang: [],
            ddJenisHutangLoading: false,

            ddKategoriPM: [],
            ddKategoriPMLoading: false,

            ddPenghasilan: [],
            ddPenghasilanLoading: false,

            ddStatusBenef: [],
            ddStatusBenefLoading: false,
        }
    },
    methods: {
        getDDCabang(){
            this.ddCabangLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "cabang").then(res => {
                this.ddCabang = res.data.detail; 
                this.ddCabangLoading = false;
            }).catch(err =>{ 
                this.ddCabang = []; 
                this.ddCabangLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDRelation(){
            this.ddRelationLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "relasi-keluarga").then(res => {
                this.ddRelation = res.data.detail; 
                this.ddRelationLoading = false;
            }).catch(err =>{ 
                this.ddRelation = []; 
                this.ddRelationLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDItem(){
            this.ddItemLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "item-kebutuhan").then(res => {
                this.ddItem = res.data.detail; 
                this.ddItemLoading = false;
            }).catch(err =>{ 
                this.ddItem = []; 
                this.ddItemLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDGender(){
            this.ddGenderLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "gender").then(res => {
                this.ddGender = res.data.detail; 
                this.ddGenderLoading = false;
            }).catch(err =>{ 
                this.ddGender = []; 
                this.ddGenderLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDOwnership(){
            this.ddOwnershipLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "kepemilikan-rumah").then(res => {
                this.ddOwnership = res.data.detail; 
                this.ddOwnershipLoading = false;
            }).catch(err =>{ 
                this.ddOwnership = []; 
                this.ddOwnershipLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDDesignRumah(){
            this.ddDesignRumahLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "design-rumah").then(res => {
                this.ddDesignRumah = res.data.detail; 
                this.ddDesignRumahLoading = false;
            }).catch(err =>{ 
                this.ddDesignRumah = []; 
                this.ddDesignRumahLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDJenisRumah(){
            this.ddJenisRumahLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "jenis-rumah").then(res => {
                this.ddJenisRumah = res.data.detail; 
                this.ddJenisRumahLoading = false;
            }).catch(err =>{ 
                this.ddJenisRumah = []; 
                this.ddJenisRumahLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDLantaiRumah(){
            this.ddLantaiRumahLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "lantai-rumah").then(res => {
                this.ddLantaiRumah = res.data.detail; 
                this.ddLantaiRumahLoading = false;
            }).catch(err =>{ 
                this.ddLantaiRumah = []; 
                this.ddLantaiRumahLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDSumberAir(){
            this.ddSumberAirLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "sumber-air").then(res => {
                this.ddSumberAir = res.data.detail; 
                this.ddSumberAirLoading = false;
            }).catch(err =>{ 
                this.ddSumberAir = []; 
                this.ddSumberAirLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDAsset(){
            this.ddAssetLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "aset-aktiva").then(res => {
                this.ddAsset = res.data.detail; 
                this.ddAssetLoading = false;
            }).catch(err =>{ 
                this.ddAsset = []; 
                this.ddAssetLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDJenisHutang(){
            this.ddJenisHutangLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "jenis-hutang").then(res => {
                this.ddJenisHutang = res.data.detail; 
                this.ddJenisHutangLoading = false;
            }).catch(err =>{ 
                this.ddJenisHutang = []; 
                this.ddJenisHutangLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDKategoriPM(){
            this.ddKategoriPMLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "kategori-pm").then(res => {
                this.ddKategoriPM = res.data.detail; 
                this.ddKategoriPMLoading = false;
            }).catch(err =>{ 
                this.ddKategoriPM = []; 
                this.ddKategoriPMLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDPenghasilan(){
            this.ddPenghasilanLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "penghasilan").then(res => {
                this.ddPenghasilan = res.data.detail; 
                this.ddPenghasilanLoading = false;
            }).catch(err =>{ 
                this.ddPenghasilan = []; 
                this.ddPenghasilanLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
        
        getDDStatusBenef(){
            this.ddStatusBenefLoading = true;
            ApiService.setHeader();
            ApiService.get(this.typeDropdown, "benef-status").then(res => {
                this.ddStatusBenef = res.data.detail; 
                this.ddStatusBenefLoading = false;
            }).catch(err =>{ 
                this.ddStatusBenef = []; 
                this.ddStatusBenefLoading = false;
                return this.$swal("Failed", this.$helpers.getTextAlert(err), "error")
            });
        },
    }
};
