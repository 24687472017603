import ApiService from "@/core/services/api.service";

export default {
    name: "MixGeneralAsync",
    async mounted() {
        // ...
    },
    data: function () {
        return {
            // ....
        }
    },
    watch: {
        // ....
    },
    methods: {
        // ........
        // #Common
        // ........

        // ::Pembantu dalam proses get
        async getAsync(resource, params){
            ApiService.setHeader();
            return await ApiService.get(resource, params)
                .then(res => { return res.data; })
                .catch(err =>{ 
                    this.$swal("Information", this.$helpers.getTextAlert(err.response), "error");
                    return false; 
                });
        },
        
        // ::Pembantu dalam proses post
        async postAsync(url, query){
            ApiService.setHeader();
            return await ApiService.post(url, query)
                .then(res => { return res.data; })
                .catch(err =>{ 
                    this.$swal("Information", this.$helpers.getTextAlert(err.response), "error");
                    return false; 
                });
        },

        // ................
        // #HelpersFunction
        // ................

        // ::Pembantu dalam proses upload
        async uploadFoto(file, tag){
            let form = new FormData;
            form.append("file", file);
            form.append("tag", tag);
            
            return await ApiService.post("beneficiaries/upload", form).then(res => {
              return res.data.detail;
            }).catch(() => { return false; });
        },
    
          
        // ................
        // #GetDataRegional
        // ................

        // ::Mengambil data negara dan kode handphonenya
        async getCountryCode() {
            ApiService.setHeader();
            return await ApiService.get("dropdown", "country-code")
            .then(({ data }) => { return data.detail })
            .catch(( err ) => { this.$swal("Failed", this.$helpers.getTextAlert(err), "error"); return [];})
        },

        // ::Mengambil data negara
        async getNegara() {
            ApiService.setHeader();
            return await ApiService.get("dropdown", "negara")
            .then(({ data }) => { return data.detail })
            .catch(( err ) => { this.$swal("Failed", this.$helpers.getTextAlert(err), "error"); return [];})
        },

        // ::Mengambil data provinsi sesuai dengan negaranya
        async getProvinsi(params) {
            if (!params) return [];

            ApiService.setHeader();
            return await ApiService.get("dropdown", "provinsi?parent_id="+params)
            .then(({ data }) => { return data.detail })
            .catch(( err ) => { this.$swal("Failed", this.$helpers.getTextAlert(err), "error"); return [];})
        },

        // ::Mengambil data kota sesuai dengan provinsinya
        async getKota(params) {
            if (!params) return [];

            ApiService.setHeader();
            return await ApiService.get("dropdown", "kota?parent_id="+params)
            .then(({ data }) => { return data.detail })
            .catch(( err ) => { this.$swal("Failed", this.$helpers.getTextAlert(err), "error"); return [];})
        },

        // ::Mengambil data kecamatan sesuai dengan parentnya
        async getKecamatan(params) {
            if (!params) return [];

            ApiService.setHeader();
            return await ApiService.get("dropdown", "kecamatan?parent_id="+params)
            .then(({ data }) => { return data.detail })
            .catch(( err ) => { this.$swal("Failed", this.$helpers.getTextAlert(err), "error"); return [];})
        },

        // ::Mengambil data kelurahan sesuai dengan kecamatannya
        async getKelurahan(params) {
            if (!params) return [];

            ApiService.setHeader();
            return await ApiService.get("dropdown", "kelurahan?parent_id="+params)
            .then(({ data }) => { return data.detail })
            .catch(( err ) => { this.$swal("Failed", this.$helpers.getTextAlert(err), "error"); return [];})
        },
    }
};
