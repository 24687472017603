import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const FORGOT_PASSWORD = "forgotPassword";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_SUCCESS = "setSuccess";

const state = {
  errors: null,
  user: JwtService.getUser(),
  module: JwtService.getModule(),
  year: String(JwtService.getYear()),
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    if(typeof(state.user) == "string"){
      return JSON.parse(state.user);
    }else{
      return state.user;
    }
  },
  currentToken(state) {
    alert(state.user.token);
    return state.token;
  },
  currentModule(state) {
    if(typeof(state.module) == "string"){
      let mdl = state.module ? state.module : "[]";
      let first = mdl.charAt(0);
      let last = mdl.slice(-1);
    
      if(first == '[' && last == ']'){
        mdl = JSON.parse(mdl);
      }else{
        mdl = [];
      }
    
      return mdl;
    }else{
      return state.module;
    }
  },
  currentYear(state) {
    return state.year;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials).then(({ data }) => {
        context.commit(SET_AUTH, data);
        resolve(data);
      }).catch(({ response }) => {
        context.commit(SET_ERROR, [response.data.message]);
        reject(response);
      });
    });
  },
  
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("logout", null).then(({ data }) => {
        context.commit(PURGE_AUTH);
        resolve(data);
      }).catch(({ response }) => {
        context.commit(PURGE_AUTH);
        reject(response);
      });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.message]);
          reject(response);
        });
    });
  },
  [FORGOT_PASSWORD](context, credentials) {
    return new Promise((resolve, reject) => {
      var formData  = new FormData();
      formData.append("username", credentials);

      ApiService.post("login/forgot", formData)
        .then(({ data }) => {
          context.commit(SET_SUCCESS, [data.status]);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.message]);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    var token = JwtService.getToken();
    if (!token || token == "undefined" || token == undefined){
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_SUCCESS](state, information) {
    state.informations = information;
  },

  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = JSON.stringify(user.user);
    state.module = user.module ? JSON.stringify(user.module) : [];
    state.errors = {};
    state.informations = {};
    JwtService.saveToken(user.token);
    JwtService.saveUser(JSON.stringify(user.user));
    JwtService.saveModule(JSON.stringify(user.module));
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.module = [];
    state.errors = {};
    state.informations = {};
    JwtService.destroyToken();
    JwtService.destroyUser();
    JwtService.destroyModule();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
