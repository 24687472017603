import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/dashboard-pages/dashboard-1/dashboard.vue"),
          meta: {
            isDashboard: true
          },
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
          meta: {
            isBuilder: true
          },
        },
        {
          path: "/ekspedisi",
          name: "ekspedisi",
          component: () => import("@/view/layout/LayoutLogistic.vue"),
          children: [   
            {
              path: "laporan/surat_jalan-list",
              name: "laporan-surat_jalan-list",
              component: () => import("@/view/pages/laporan/surat_jalan/list.vue"), 
              meta: {
                isLaporan: true,
                isModule : "M06"
              },
            },
            {
              path: "transactions/surat_jalan-list",
              name: "transactions-surat_jalan-list",
              component: () => import("@/view/pages/transactions/surat_jalan/list.vue"), 
              meta: {
                isTransaction: true,
                isModule : "M04"
              },
            },
            {
              path: "transactions/surat_jalan-create",
              name: "transactions-surat_jalan-create",
              component: () => import("@/view/pages/transactions/surat_jalan/index.vue"), 
              meta: {
                isTransaction: true,
                isModule : "M04"
              },
            },
            {
              path: "transactions/surat_jalan-update/:id",
              name: "transactions-update-surat_jalan",
              component: () => import("@/view/pages/transactions/surat_jalan/update.vue"), 
              meta: {
                isTransaction: true,
                isModule : "M04"
              }
            },
            {
              path: "transactions/surat_jalan/detail/:id",
              name: "transactions-surat_jalan",
              component: () => import("@/view/pages/transactions/surat_jalan/detail.vue"), 
              meta: {
                isTransaction: true,
                isModule : "M04"
              },
            },
            {
              path: "master/news",
              name: "master-news",
              component: () => import("@/view/pages/master/news/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            {
              path: "master/kendaraan",
              name: "master-kendaraan",
              component: () => import("@/view/pages/master/kendaraan/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            {
              path: "master/jenis_pengiriman",
              name: "master-jenis_pengiriman",
              component: () => import("@/view/pages/master/jenis_pengiriman/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            {
              path: "master/jenis_pengiriman_detail",
              name: "master-jenis_pengiriman_detail",
              component: () => import("@/view/pages/master/jenis_pengiriman_detail/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            {
              path: "master/satuan",
              name: "master-satuan",
              component: () => import("@/view/pages/master/satuan/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            {
              path: "master/ongkos_kirim",
              name: "master-ongkos_kirim",
              component: () => import("@/view/pages/master/ongkos_kirim/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            {
              path: "master/status_tracking",
              name: "master-status_tracking",
              component: () => import("@/view/pages/master/status_tracking/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            {
              path: "master/tipe_pembayaran",
              name: "master-tipe_pembayaran",
              component: () => import("@/view/pages/master/tipe_pembayaran/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            {
              path: "master/driver",
              name: "master-driver",
              component: () => import("@/view/pages/master/driver/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            {
              path: "master/customer",
              name: "master-customer",
              component: () => import("@/view/pages/master/customer/list.vue"), 
              meta: {
                isMaster: true
              },
            },
            
            {
              path: "setup/parameter",
              name: "setup-parameter",
              component: () => import("@/view/pages/setup/parameter/list.vue"), 
              meta: {
                isSetup: true
              },
            }, 
            
            {
              path: "users",
              name: "users",
              component: () => import("@/view/pages/user-pages/user-list/user-list.vue"), 
              meta: {
                isUser: true
              },
            },
            {
              path: "modul",
              name: "modul",
              component: () => import("@/view/pages/user-pages/modul/list.vue"), 
              meta: {
                isSetup: true
              },
            },
            {
              path: "permision",
              name: "permision",
              component: () => import("@/view/pages/user-pages/permision/list.vue"), 
              meta: {
                isUser: true
              },
            },
            {
              path: "modul_permision",
              name: "modul_permision",
              component: () => import("@/view/pages/user-pages/modulpermision/list.vue"), 
              meta: {
                isUser: true
              },
            },
            {
              path: "user-roles",
              name: "user-roles",
              component: () => import("@/view/pages/user-pages/role/list.vue"), 
              meta: {
                isUser: true
              },
            },
            
            {
              path: "change-password",
              name: "change-password",
              component: () => import("@/view/pages/user-pages/change-password/index.vue")
            }, 
            // Modul Pasar sedekah 
            
          ]
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        },
        {
          name: "forgot-password",
          path: "/forgot-password",
          component: () => import("@/view/pages/auth/ForgotPassword")
        },
        {
          name: "status_tracking",
          path: "/status_tracking/:id",
          component: () => import("@/view/pages/transactions/surat_jalan/tracking.vue"), 
        },
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    },
   
  ]
});
