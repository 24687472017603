import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  
  setHeaderMultipart() {
    Vue.axios.defaults.headers.common[
      'Content-Type'      
    ] = 'multipart/form-data';
  },

  query(resource, params, header = null) {
    if(header == 1) this.setHeader();
    
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = null, header = null,api =null) {
    if(header == 1) this.setHeader();

    if(api == null){
      Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    }else{
      Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL_TWC;
    }

    
    var sub = slug ? '/'+slug : '';
    var url = `${resource}` + sub;
    return Vue.axios.get(url).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, header) {
    if(header == 1) this.setHeader();

    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return Vue.axios.post(`${resource}`, params);
  },
  
  // postMultipart(resource, params) {
  //   Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  //   // Vue.axios.defaults.setHeaderMultipart = {
  //   //   'Content-Type': 'multipart/form-data'
  //   // }
  //   // Vue.axios.defaults.setHeader = {
  //   //   'Content-Type': 'multipart/form-data'
  //   // }
  //   const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  //   return Vue.axios.post(`${resource}`, params, config);
  // },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params, header = null) {
    if(header == 1) this.setHeader();

    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, header = null) {
    if(header == 1) this.setHeader();

    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, header = null) {
    if(header == 1) this.setHeader();

    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
