export const loadAlertFailed = (res, text) => {
    var msg = text;
    if(res){
        if(res.data){
            if(res.data.error){
                msg = res.data.error;
            }
        }
    }
    console.log(msg);
    console.log(res);
    this.$swal("Failed", msg, "error");
};

export const getTextAlert = (res, text = null) => {
    var msg = text ? text : "Terjadi Kesalahan";
    if(res){
        if(res.data){
            if(res.data.message){
                msg = res.data.message;
            }
        }
    }
    return msg;
};

export const formatPrice = (number) => {
    let val = (number/1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export default { 
    loadAlertFailed,
    formatPrice,
    getTextAlert
 };